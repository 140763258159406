import React, { useState, useEffect, useRef } from "react";
import HomeNav from "./HomeNav";
import { motion } from "framer-motion";
import AnimatedBanner3 from "./AnimatedBanner3";
import
{
  ListingType,
  formatValue,
  buttonName,
  serverAPI,
  CategoryWeights,
  toTitleCase,
} from "../utils/envConfig";
import Link from "next/link";
import { useDispatch, useSelector } from "react-redux";
import AutoPopulate from "./AutoPopulate";
import { fetchCategory, fetchPropTypes } from "../redux/actions/property";
import CurrencyInput from "./CurrencyInput";
import DropDownOffPlan from "./DropDownOffPlan";
import axios from "axios";
import Image from "next/image";

const HomeBanner = ( { showGetMatched, showNewListingForm } ) =>
{
  const dispatch = useDispatch();

  const listingTypeRef = useRef();
  const filterRef = useRef();
  const listingTypeMobRef = useRef();
  const filterMobRef = useRef();
  const filterBedsRef = useRef();

  const categories = useSelector( ( state ) => state.property.categories );

  const propType = useSelector( ( state ) => state.property.propertyTypes );


  const [ comm, setComm ] = useState( [] );
  const [ buildings, setBuildings ] = useState( [] );

  const [ lType, setLType ] = useState( ListingType[ 0 ] );
  const [ developer, setDeveloper ] = useState();
  const [ isOpenDeveloper, setIsOpenDeveloper ] = useState( false );
  const [ isOpenBeds, setIsOpenBeds ] = useState( false );
  const [ minPrice, setMinPrice ] = useState(
    lType === ListingType[ 0 ] ? 0 : 0
  );
  const [ maxPrice, setMaxPrice ] = useState(

  );

  const [ selectedOption, setSelectedOption ] = useState( "Buy" );
  // Initialize with null to indicate loading state
  const [ activeCategory, setActiveCategory ] = useState( null );

  const [ typeToIdMap, setTypeToIdMap ] = useState( [] );

  const [ isOpen, setIsOpen ] = useState( false );
  const [ isOpenType, setIsOpenType ] = useState( false );

  const [ selectedConditions, setSelectedConditions ] = useState( [] );
  const [ selectedBeds, setSelectedBeds ] = useState( [] );
  const [ downPayment, setDownPayment ] = useState();
  const [ paymentPlan, setPaymentPlan ] = useState();

  const [ offPlanCommunities, setOffPlanCommunities ] = useState( [] );
  const [ offPlanDevelopers, setOffPlanDevelopers ] = useState( [] );

  //  Night mode
  const [ change, setChange ] = useState( false );

  useEffect( () =>
  {
    document.addEventListener( "mousedown", handleClickOutside );

    return () => document.removeEventListener( "mousedown", handleClickOutside );
  }, [] );

  useEffect( () =>
  {
    !categories?.length && dispatch( fetchCategory() ); //fetch categories only if there is no data
    !propType?.length && dispatch( fetchPropTypes() ); //fetch propType only if there is no data

    const hour = new Date().getHours();

    // Set timer to switch day and night mode
    const timer = setInterval( () =>
    {
      // We want to show night mode from 6pm t0 6am
      setChange( hour >= 18 || hour < 6 );
    }, 60 * 1000 * 60 );

    setChange( hour >= 18 || hour < 6 );

    return () =>
    {
      clearInterval( timer );
    };
  }, [] );

  const handleClickOutside = ( e ) =>
  {
    const { current: listingType } = listingTypeRef;
    const { current: listingTypeMob } = listingTypeMobRef;
    const { current: filter } = filterRef;
    const { current: filterMob } = filterMobRef;
    const { current: filterBeds } = filterBedsRef;

    if ( ( listingType && !listingType.contains( e.target ) ) && ( listingTypeMob && !listingTypeMob.contains( e.target ) ) )
    {
      setIsOpenType( false );
    }
    if ( ( filter && !filter.contains( e.target ) ) && ( filterMob && !filterMob.contains( e.target ) ) )
    {
      setIsOpen( false );
    }
    if ( ( filterBeds && !filterBeds.contains( e.target ) ) )
    {
      setIsOpenBeds( false );
    }

  };

  // Effect to set default active category when categories are loaded
  useEffect( () =>
  {
    // Check if categories array is not empty and index 1 exists
    if ( categories?.length > 1 )
    {
      const found = categories
        ?.map( ( item ) => ( {
          ...item,
          weight: CategoryWeights[ item?.category ],
        } ) )
        ?.sort( ( a, b ) => b?.weight - a?.weight )
        ?.find( ( item ) => item?.weight === CategoryWeights.Residential );

      setActiveCategory( {
        value: found?.id,
        label: found?.category,
        name: "category",
        title: "Category",
      } );
    }

    return () =>
    {

    };
  }, [categories, categories?.length] );



  useEffect( () =>
  {
    if ( propType )
    {
      const allowedTypes = [ "apartment", 'villa', 'plot', 'townhouse' ];

      const filteredTypes = propType.filter( ( item ) =>
      {
        return allowedTypes.includes( item?.propertyType?.toLowerCase() );
      } );

      setTypeToIdMap( filteredTypes );
    }

  }, [propType, propType.length] );

  const handleCategoryChange = ( category, label ) =>
  {
    if ( category === 'off-plan' )
    {
      setActiveCategory( _ => category );

    } else
    {
      setActiveCategory( _ => ( { name: category, value: category, label, title: 'Category' } ) );
    }
    if ( category === activeCategory )
    {
      return;
    }
    // setSelectedTypes( [] );
    setSelectedType( null );

    const filtered = propType?.filter( ( item ) =>
    {
      const selectedCategory = categories?.find( ( item ) => item?.id === category );
      if ( category === "off-plan" )
      {
        return (
          item?.propertyType?.toLowerCase() === "office" ||
          item?.propertyType?.toLowerCase() === "store" ||
          item?.propertyType?.toLowerCase() === "apartment" ||
          item?.propertyType?.toLowerCase() === "villa" ||
          item?.propertyType?.toLowerCase() === "townhouse"
        );
      } else
      {
        return (
          item?.categoryId?.toString() === selectedCategory?.id?.toString()
        );
      }
    } );

    setTypeToIdMap( filtered );
  };



  const toggleDropdownFilters = ( close ) =>
  {
    setIsOpen( close ?? !isOpen );
    setIsOpenType( false );
    setIsOpenBeds( false );
  };

  const toggleDropdownBeds = ( close ) =>
  {
    setIsOpenBeds( close ?? !isOpenBeds );
    setIsOpenType( false );
    setIsOpen( false );

  };
  const toggleDropdownType = ( close ) =>
  {
    setIsOpenType( close ?? !isOpenType );
    setIsOpen( false );
    setIsOpenBeds( false );

  };

  const arrowDown = <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" /></svg>;
  const arrowUp = <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clipRule="evenodd" /></svg>;

  const selectOption = ( option ) =>
  {
    setSelectedOption( option );
    setIsOpenType( false ); // Close the dropdown after selection
  };

  // Filters
  const handleOnClickRent = () =>
  {
    selectOption( 'Rent' );
    setLType( ListingType[ 1 ] );
  };

  const handleOnClickBuy = () =>
  {
    selectOption( 'Buy' );
    setLType( ListingType[ 0 ] );
  };

  const handleMinPriceChange = ( event ) =>
  {
    setMinPrice( Number( event.target.value?.toString()?.replaceAll( ',', '' ) ) );
  };

  const handleMaxPriceChange = ( event ) =>
  {
    setMaxPrice( Number( event.target.value?.toString()?.replaceAll( ',', '' ) ) );
  };

  const [ selectedType, setSelectedType ] = useState( [] );

  const handleCheckboxChange = ( e, type, label ) =>
  {
    const isChecked = selectedType?.value === type;

    if ( !isChecked )
    {
      setSelectedType( { name: 'propertyType', value: type, label: label, title: 'Property Types' } );
    } else
    {
      setSelectedType( null );
    }
  };

  const handleConditionCheckboxChange = ( e, condition, label ) =>
  {

    const isChecked = selectedConditions?.some( ( e ) => e.value === condition );

    if ( !isChecked )
    {
      setSelectedConditions( [ ...selectedConditions, { name: 'condition', value: condition, label: label, title: 'Condition' } ] );
    } else
    {
      setSelectedConditions( selectedConditions.filter( ( selectedType ) => selectedType.value !== condition ) );
    }
  };

  const handleBedsCheckboxChange = ( e, beds, label ) =>
  {

    const isChecked = selectedBeds?.some( ( e ) => e.value === beds.value );

    if ( !isChecked )
    {
      setSelectedBeds( [ ...selectedBeds, beds ] );
    } else
    {
      setSelectedBeds( selectedBeds.filter( ( selectedType ) => selectedType.value !== beds.value ) );
    }
  };

  const toggleDropdown = ( label, isOpen, action = 'toggle' ) =>
  {
    setIsOpenDeveloper( !isOpenDeveloper );
  };

  useEffect( () =>
  {
    if ( offPlanCommunities && offPlanCommunities.length > 0 )
    {
      return;
    }
    let fetchCommunities = async () =>
    {
      const offPlansReq = await axios.get( `${ serverAPI }/off-plan/search` );
      const developersReq = await axios.get( `${ serverAPI }/off-plan/plan-developer` );
      const communities = offPlansReq.data?.filteredOutput.map( ( e ) => e.community );
      const developers = developersReq.data;
      setOffPlanCommunities( communities );
      setOffPlanDevelopers( developers );
    };
    fetchCommunities();
  }, [offPlanCommunities] );

  useEffect( () =>
  {
    setComm( [] );
    let fetchCommunities = async () =>
    {
      const offPlansReq = await axios.get( `${ serverAPI }/off-plan/search?developers=${ developer?.developers?.value }` );
      const communities = offPlansReq.data?.filteredOutput.map( ( e ) => e.community );
      setOffPlanCommunities( communities );
    };
    if ( developer?.developers?.value )
    {
      fetchCommunities();
    }
  }, [ developer ] );

  return (
    <div
      className={ `lg:h-[37rem] xl:h-[49rem] 2xl:h-[55rem] 3xl:h-[62rem] 4xl:h-[62rem] 6xl:h-[70rem] md:h-[35rem] 
    4xs:h-[52rem] 3xs:h-[51rem] 2.5xs:h-[52rem] 2xs:h-[56rem] xs:h-[61rem]  sm:h-[68rem]  relative  ${ change ? "bg-main-banne-night" : "bg-main-banner"
        }` }
    >
      <HomeNav showNewListingForm={ showNewListingForm } />
      {/* Floating city animation  */ }
      <div
        className="absolute hidden md:block z-10 pointer-events-none md:top-0 city-animation mt-28 md:mt-[2rem] sm:mt-20  
      lg:mt-0 4xl:scale-90 4xl:mt-0"
      >
        <img alt='banner-image' src="/lotties/images/img_0.webp" />
      </div>
      {/* Floating city tail */ }

      {/* For Mobile : static image */ }
      <div className="absolute md:hidden block z-10 pointer-events-none top-24 scale-125 ml-2">
        <img alt='banner-image-mobile' src="/lotties/images/img_0.webp" />
      </div>
      { !change && (
        <>
          <div className="absolute pointer-events-none">
            <AnimatedBanner3 />
          </div>
          {/* Backgroud static image day*/ }
          <Image
            alt="gradient-clouds"
            layout="fill"
            quality={50}
            src="/img/gradientupdated.webp"
            id="bgrid"
            className="object-cover object-bottom"
          />
        </>
      ) }
      {/* Backgroud static image night*/ }
      { change && (
        <Image
          alt="gradient-clouds-night"
          layout="fill"
          quality={50}
          src="/img/night-sky.webp "
          id="bgrid"
          className="object-cover object-bottom"
        />
      ) }
      <div className="absolute z-10 2xl:top-[265px] lg:top-36 xl:top-44 md:top-36 sm:top-32  top-[8rem] left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full">
        <h1 className="hidden md:block text-white xl:text-5xl lg:text-3xl md:text-2xl text-[20px] font-light font-Mont leading-10 tracking-wider text-center">
          Discover Dubai&nbsp;&nbsp;&nbsp;&nbsp; Discover Home{ " " }
        </h1>
        <h1 className="md:hidden text-center  text-white sm:text-[26px] 4xs:text-[1.2rem] xs:text-[1.6rem]  font-bold font-Mont leading-10 ">
          Discover Dubai&nbsp;&nbsp;&nbsp;  Discover Home
        </h1>
      </div>
      {/* find your match box start */ }
      <div className="container lg:max-w-[930px] mx-auto  ">
        <div
          className="hidden customStyle lg:block absolute z-20 lg:w-[63rem] 2xl:w-[72rem] xl:w-[60rem] container mx-auto left-0 right-0
       transform 2xl:translate-y-[48rem]  4xl:translate-y-[56rem] 5xl:translate-y-[58rem]
       6xl:translate-y-[61rem] xl:translate-y-[40rem] lg:translate-y-[32rem] md:translate-y-[28rem]
       translate-y-[18rem]  dark:bg-night px-6 py-5  rounded-[1rem] "
        >
          <div className="bg-night flex justify-between  rounded-tr-3xl rounded-br-3xl rounded-bl-3xl flex-col ">
            <div className=" rounded-[15px] !rounded-tl-none h-24 relative " style={ {
              boxShadow: "inset 0 4px 6px rgb(26 33 44)",
              borderRadius: "2rem"
            } }>
              <div className="absolute h-[75px] bg-night lg:w-[38%] 2xl:w-[33%]  
              -top-[75px] rounded-tr-3xl rounded-tl-3xl
        
              " >
                <div className=" ">
                  <div className="flex ml-5 pt-4 items-center space-x-7">
                    {/* Mapping categories starting from index 1 */ }
                    { categories?.map( ( item ) => ( {
                      ...item,
                      weight: CategoryWeights[ item?.category ],
                    } ) )
                      ?.sort( ( a, b ) => b?.weight - a?.weight )?.filter( item => item?.weight || item?.weight === 0 )?.map( cat => (
                        <button
                          key={ cat?.id }
                          onClick={ () => handleCategoryChange( cat?.id, cat?.category ) }
                          className={ ` font-Lexend font-semibold 
                         py-2 cursor-pointer ${ activeCategory?.value === cat?.id ? "border-b-2 border-[#FFDEAB] text-[#FFDEAB]" : "hover:text-[#FFDEAB] text-white"
                            }` }
                        >
                          { cat?.category }
                        </button>
                      ) )
                    }

                    <button
                      key={ 'off-plan' }
                      onClick={ () => handleCategoryChange( 'off-plan' ) }
                      className={ `font-Lexend font-semibold 
                         py-2 cursor-pointer ${ activeCategory === 'off-plan' ? "border-b-2 border-[#FFDEAB] text-[#FFDEAB]" : "hover:text-[#FFDEAB] text-white"
                        }` }
                    >
                      Off Plan
                    </button>


                  </div>

                  <div>
                    <div className="flex flex-row items-center  space-x-2 pt-10 pl-5">
                      { activeCategory !== 'off-plan' ? <div className="relative inline-block text-left">
                        <div>
                          <button
                            type="button"
                            className="inline-flex justify-center items-center w-full rounded-full border border-night
                  px-7 py-3 bg-white hover:bg-[#FFDEAB] text-base font-semibold font-Lexend text-navy"
                            onClick={ toggleDropdownType }
                          >
                            { selectedOption } { isOpenType ? arrowUp : arrowDown }
                          </button>
                        </div>

                        { isOpenType && (
                          <div
                            className="origin-top-left absolute left-0 mt-2 w-36 rounded-md shadow-lg
                   bg-white ring-1 ring-black ring-opacity-5 focus:outline-none p-4"
                            style={ { zIndex: 1000 } }
                          >
                            <ul ref={ listingTypeRef }>
                              <li
                                className="mb-4 text-navy font-Lexend text-base font-semibold hover:text-[#99700D] cursor-pointer"
                                onClick={ handleOnClickBuy }
                              >
                                Buy
                              </li>
                              <li
                                className=" text-navy font-Lexend text-base font-semibold hover:text-[#99700D] cursor-pointer"
                                onClick={ handleOnClickRent }
                              >
                                Rent
                              </li>
                            </ul>
                          </div>
                        ) }
                      </div> :
                        <DropDownOffPlan
                          label="Developer"
                          name='developers'
                          isOpen={ isOpenDeveloper }
                          toggleDropdown={ toggleDropdown }
                          items={ offPlanDevelopers.map( ( e ) =>
                          {

                            return ( { name: 'developers', label: e.name, value: e._id, title: 'Developer' } );
                          } ) }
                          onSelectionChange={ ( item ) => setDeveloper( item ) }
                          selectedItem={ developer ? {
                            ...developer,
                            label: developer?.label
                          } : {} }
                          spanClass={ "max-w-[5rem] truncate text-ellipsis overflow-hidden whitespace-nowrap font-[15px]" }
                          containerClassName={ "border border-night inline-flex py-3 text-base font-semibold" }


                        />
                      }
                      <AutoPopulate setComm={ setComm } setBuilding={ setBuildings } category={ activeCategory } offPlanCommunities={ offPlanCommunities } />
                      { activeCategory?.label !== 'Commercial' &&
                        <motion.div
                          initial={ { opacity: 0, width: 0 } }
                          animate={ { opacity: activeCategory?.label !== 'Commercial' ? 1 : 0, width: activeCategory?.label !== 'Commercial' ? 'auto' : 0 } }
                          transition={ { duration: 0.3 } }

                          style={ { zIndex: 1000 } }
                          ref={ filterBedsRef }
                        >
                          <div className="relative">
                            <button className="inline-flex justify-center items-center w-full rounded-full border border-night
                      px-7 py-3 bg-white hover:bg-[#FFDEAB] text-base font-semibold font-Lexend text-navy" type="button" onClick={ toggleDropdownBeds }>
                              Beds{ isOpenBeds ? arrowUp : arrowDown }
                            </button>
                            <motion.div
                              initial={ { opacity: 0, height: 0 } }
                              animate={ { opacity: isOpenBeds ? 1 : 0, height: isOpenBeds ? 'auto' : 0 } }
                              transition={ { duration: 0.1 } }
                              className="origin-top-left absolute left-0 mt-2 w-96 rounded-md shadow-lg bg-white 
                              ring-1 ring-black ring-opacity-5 focus:outline-none p-4"
                              style={ { zIndex: 1000 } }

                            >
                              <div className="space-y-6">

                                <fieldset className="flex flex-wrap gap-4">
                                  <legend className="block text-base font-bold font-Lexend text-gray-700 mb-4">
                                    Beds
                                  </legend>
                                  { [ 'Studio', '1', '2', '3', '4', '5', '6', '7+' ].map( e => ( { title: 'Beds', name: "beds", label: !isNaN( Number( e.replace( '+', '' ) ) ) ? `${ e } Beds` : e, value: e } ) ).map( ( beds ) => (

                                    <button
                                      key={ beds.value }
                                      className="relative flex items-center rounded-full cursor-pointer"
                                    >
                                      <input
                                        id={ beds.value }
                                        name="beds"
                                        type="checkbox"
                                        className="absolute opacity-0 w-0 h-0"
                                        onChange={ ( e ) => handleBedsCheckboxChange( e, beds, "beds" ) }
                                        checked={ selectedBeds.some( e => e.value === beds.value ) }
                                      />
                                      <label
                                        htmlFor={ beds.value }
                                        className={ `flex items-center w-full h-full rounded-full cursor-pointer hover:bg-[#E3EFFA] hover:text-night
                transition-colors duration-300 ease-in-out shadow-customnavy px-4 py-2
                ${ selectedBeds.some( e => e.value === beds.value ) ? 'bg-[#E3EFFA] text-night border border-navy shadow-none' : 'bg-white text-gray-700' }` }
                                      >
                                        <span className="flex items-center justify-center">
                                          { selectedBeds.some( e => e.value === beds.value ) && (
                                            <svg className="w-4 h-4 mr-2 text-white" fill="none" stroke="#1e293b" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                            </svg>
                                          ) }
                                        </span>
                                        <span className="block text-sm font-medium">
                                          { beds.label }
                                        </span>
                                      </label>
                                    </button>
                                  ) ) }
                                </fieldset>

                                <Link
                                  passHref
                                  href={ {
                                    pathname: activeCategory === 'off-plan' ? "/off-plan-dubai" : "/find-the-properties",
                                    query: {
                                      filter: Buffer.from( JSON.stringify( {
                                        // ...( selectedTypes.length && selectedTypes.length > 0 && { propertyType: selectedTypes } ),
                                        ...( selectedType && { propertyType: selectedType } ),
                                        ...( comm?.length && comm.length > 0 && { community: comm } ),
                                        ...( developer && activeCategory === 'off-plan' && developer ),
                                        ...( buildings && buildings.length > 0 && activeCategory !== 'off-plan' && { building_id: buildings } ),
                                        ...( lType && activeCategory !== 'off-plan' && { listingType: { name: "listingType", value: lType, label: lType, title: 'Purpose' } } ),
                                        ...minPrice && { minPrice: { value: minPrice, label: `AED ${ formatValue( minPrice ) }`, title: 'Min Price' } },
                                        ...maxPrice && { maxPrice: { value: maxPrice, label: `AED ${ formatValue( maxPrice ) }`, title: 'Max Price' } },
                                        ...selectedBeds && selectedBeds.length > 0 && { beds: selectedBeds },
                                        ...activeCategory && activeCategory !== 'off-plan' && { category: activeCategory },
                                        ...selectedConditions.length && selectedConditions.length > 0 && { conditions: selectedConditions },
                                      } ) ).toString( 'base64' )
                                    },
                                  } }
                                >
                                  <button className="flex justify-center py-2 px-4 mb-4 border border-transparent rounded-md 
          shadow-sm text-base font-bold text-navy bg-[#DFC090] hover:bg-night hover:text-[#FFDEAB]">
                                    Apply Filters
                                  </button>
                                </Link>

                              </div>
                            </motion.div>
                          </div>
                        </motion.div>
                      }


                      <div className="relative inline-block text-left">
                        <div>
                          <button
                            type="button"
                            className="inline-flex justify-center items-center w-full rounded-full border border-night
                  px-7 py-3 bg-white hover:bg-[#FFDEAB] text-base font-semibold font-Lexend text-navy"
                            onClick={ toggleDropdownFilters }
                          >
                            Filters { isOpen ? arrowUp : arrowDown }
                          </button>
                        </div>

                        { isOpen && (
                          <div
                            className="origin-top-left absolute left-0 mt-3 w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none p-4"
                            style={ { zIndex: 1000 } }
                            ref={ filterRef }
                          >
                            <div className="space-y-4" >
                              <div className="flex gap-4">
                                <div>
                                  <label className="block text-base font-bold text-gray-700">
                                    Min Price
                                  </label>

                                  <CurrencyInput
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                                    onChange={ handleMinPriceChange }
                                    placeholder={ formatValue( 10000 ) }
                                  />
                                </div>
                                <div>
                                  <label className="block text-base font-bold text-gray-700">
                                    Max Price
                                  </label>
                                  <CurrencyInput
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                                    onChange={ handleMaxPriceChange }
                                    max={ 10000000000 }
                                    placeholder={ formatValue( 100000000 ) }
                                  />

                                </div>
                              </div>

                              { activeCategory.label === 'Commercial' && <fieldset className="flex flex-wrap ">
                                <legend className="block text-base font-bold font-Lexend text-gray-700">
                                  Conditions
                                </legend>
                                { [ "Shell and Core", "Fitted" ].map( ( condition ) => (
                                  <div
                                    key={ condition }
                                    className="relative flex items-center mt-3 cursor-pointer"

                                  >
                                    <input
                                      id={ condition }
                                      name="conditions"
                                      type="checkbox"
                                      className="absolute opacity-0 w-0 h-0"
                                      onChange={ ( e ) => handleConditionCheckboxChange( e, condition, "condition" ) }
                                      checked={ selectedConditions.some( e => e.value === condition ) }
                                    />
                                    <label
                                      htmlFor={ condition }
                                      className={ `flex items-center w-fit h-full  rounded-full mr-4 transition-colors
                                       cursor-pointer shadow-customnavy px-4 py-2 hover:bg-[#E3EFFA] hover:text-navy
                                       duration-300 ease-in-out ${ selectedConditions.some( e => e.value === condition ) ?
                                          'bg-[#E3EFFA] text-navy border border-night shadow-none' : 'bg-white text-gray-700'
                                        }` }
                                    >
                                      <span className="flex items-center justify-center">
                                        { selectedConditions.some( e => e.value === condition ) && (
                                          <svg className="w-4 h-4 mr-2 text-white" fill="none" stroke="#1a212c" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                          </svg>
                                        ) }
                                      </span>
                                      <span className="block text-sm font-medium">
                                        { condition }
                                      </span>
                                    </label>
                                  </div>
                                ) ) }
                              </fieldset> }

                              { activeCategory === 'off-plan' && <fieldset className="flex flex-wrap ">
                                <legend className="block text-base font-bold font-Lexend text-gray-700">
                                  Payment Plan
                                </legend>
                                { [ 'full payment on delivery', 'post-handover payment plan' ].map( e => ( { name: 'paymentPlan', label: toTitleCase( e ), value: e } ) ).map( ( val ) => (
                                  <div
                                    key={ val.value }
                                    className="relative flex items-center mt-3 cursor-pointer"

                                  >
                                    <input
                                      id={ val.value }
                                      name="paymentPlan"
                                      type="checkbox"
                                      className="absolute opacity-0 w-0 h-0"
                                      onChange={ ( e ) => paymentPlan?.value !== val.value ? setPaymentPlan( val ) : setPaymentPlan() }
                                      checked={ paymentPlan?.value === val.value }
                                    />
                                    <label
                                      htmlFor={ val.value }
                                      className={ `flex items-center w-fit h-full  rounded-full mr-4 transition-colors
                                       cursor-pointer shadow-customnavy px-4 py-2 hover:bg-[#E3EFFA] hover:text-navy
                                       duration-300 ease-in-out ${ paymentPlan?.value === val.value ?
                                          'bg-[#E3EFFA] text-navy border border-night shadow-none' : 'bg-white text-gray-700'
                                        }` }
                                    >
                                      <span className="flex items-center justify-center">
                                        { paymentPlan?.value === val.value && (
                                          <svg className="w-4 h-4 mr-2 text-white" fill="none" stroke="#1a212c" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                          </svg>
                                        ) }
                                      </span>
                                      <span className="block text-sm font-medium">
                                        { val.label }
                                      </span>
                                    </label>
                                  </div>
                                ) ) }
                              </fieldset> }


                              { activeCategory === 'off-plan' && <fieldset className="flex flex-wrap ">
                                <legend className="block text-base font-bold font-Lexend text-gray-700">
                                  DownPayment
                                </legend>
                                { [ 'Less than 20%', '20-30%', 'More than 30%' ].map( e => ( { name: 'downPayment', label: e, value: e === 'Less than 20%' ? JSON.stringify( { max: 20 } ) : e === '20-30%' ? JSON.stringify( { min: 20, max: 30 } ) : JSON.stringify( { min: 30 } ) } ) ).map( ( val ) => (
                                  <div
                                    key={ val.value }
                                    className="relative flex items-center mt-3 cursor-pointer"

                                  >
                                    <input
                                      id={ val.value }
                                      name="downPayment"
                                      type="checkbox"
                                      className="absolute opacity-0 w-0 h-0"
                                      onChange={ ( e ) => downPayment?.value !== val.value ? setDownPayment( val ) : setDownPayment() }
                                      checked={ downPayment?.value === val.value }
                                    />
                                    <label
                                      htmlFor={ val.value }
                                      className={ `flex items-center w-fit h-full  rounded-full mr-4 transition-colors
                                       cursor-pointer shadow-customnavy px-4 py-2 hover:bg-[#E3EFFA] hover:text-navy
                                       duration-300 ease-in-out ${ downPayment?.value === val.value ?
                                          'bg-[#E3EFFA] text-navy border border-night shadow-none' : 'bg-white text-gray-700'
                                        }` }
                                    >
                                      <span className="flex items-center justify-center">
                                        { downPayment?.value === val.value && (
                                          <svg className="w-4 h-4 mr-2 text-white" fill="none" stroke="#1a212c" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                          </svg>
                                        ) }
                                      </span>
                                      <span className="block text-sm font-medium">
                                        { val.label }
                                      </span>
                                    </label>
                                  </div>
                                ) ) }
                              </fieldset> }

                              <fieldset className="flex flex-wrap ">
                                <legend className="block text-base font-bold font-Lexend text-gray-700">
                                  Type
                                </legend>
                                { typeToIdMap?.map( ( type ) => (
                                  <div
                                    key={ type.id }
                                    className="relative flex items-center mt-3 cursor-pointer"
                                  >
                                    <input
                                      id={ type.id }
                                      name={ type.propertyType }
                                      type="checkbox"
                                      className="absolute opacity-0 w-0 h-0"
                                      onChange={ ( e ) => handleCheckboxChange( e, type.id, type.propertyType ) }
                                      // checked={ selectedTypes.some( ( e ) => e.value === type.id ) }
                                      checked={ selectedType?.value === type.id }
                                    />
                                    {/* ${ selectedTypes.some( ( e ) => e.value === type.id ) ? 'bg-[#E3EFFA] text-navy border border-night shadow-none' : 'bg-white text-gray-700'} */ }
                                    <label
                                      htmlFor={ type.id }
                                      className={ `flex items-center h-full px-4 py-2 rounded-full mr-3 shadow-customnavy cursor-pointer
                                      hover:bg-[#E3EFFA] hover:text-navy
                                      transition-colors duration-300 ease-in-out
                                      ${ selectedType?.value === type.id ? 'bg-[#E3EFFA] text-navy border border-night shadow-none' : 'bg-white text-gray-700' }`
                                      }
                                    >
                                      <span className=" flex items-center justify-center">
                                        { selectedType?.value === type.id && (
                                          <svg className="w-4 h-4 text-white mr-2" fill="none" stroke="#1a212c" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                          </svg>
                                        ) }
                                      </span>
                                      <span className="block text-sm font-medium capitalize">
                                        { type.propertyType }
                                      </span>
                                    </label>
                                  </div>
                                ) ) }
                              </fieldset>

                              <Link
                                passHref
                                href={ {
                                  pathname: activeCategory === 'off-plan' ? "/off-plan-dubai" : "/find-the-properties",
                                  query: {
                                    filter: Buffer.from( JSON.stringify( {
                                      // ...( selectedTypes.length && selectedTypes.length > 0 && { propertyType: selectedTypes } ),
                                      ...( selectedType && { propertyType: selectedType } ),
                                      ...( comm?.length && comm.length > 0 && { community: comm } ),
                                      ...( developer && activeCategory === 'off-plan' && developer ),
                                      ...( downPayment && activeCategory === 'off-plan' && { downPayment: downPayment } ),
                                      ...( paymentPlan && activeCategory === 'off-plan' && { paymentPlan: paymentPlan } ),
                                      ...( buildings && buildings.length > 0 && activeCategory !== 'off-plan' && { building_id: buildings } ),
                                      ...( lType && activeCategory !== 'off-plan' && { listingType: { name: "listingType", value: lType, label: lType, title: 'Purpose' } } ),
                                      ...minPrice && { minPrice: { value: minPrice, label: `AED ${ formatValue( minPrice ) }`, title: 'Min Price' } },
                                      ...maxPrice && { maxPrice: { value: maxPrice, label: `AED ${ formatValue( maxPrice ) }`, title: 'Max Price' } },
                                      ...selectedBeds && selectedBeds.length > 0 && { beds: selectedBeds },
                                      ...activeCategory && activeCategory !== 'off-plan' && { category: activeCategory },
                                      ...selectedConditions.length && selectedConditions.length > 0 && { conditions: selectedConditions },
                                    } ) ).toString( 'base64' )
                                  },
                                } }

                              >
                                <button className="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-base font-bold text-navy bg-[#DFC090] hover:bg-night hover:text-[#FFDEAB] ">
                                  Apply Filters
                                </button>

                              </Link>
                            </div>
                          </div>
                        ) }
                      </div>
                      <Link
                        passHref
                        href={ {
                          pathname: activeCategory === 'off-plan' ? "/off-plan-dubai" : "/find-the-properties",
                          query: {
                            filter: Buffer.from( JSON.stringify( {
                              // ...( selectedTypes.length && selectedTypes.length > 0 && { propertyType: selectedTypes } ),
                              ...( selectedType && { propertyType: selectedType } ),
                              ...( comm?.length && comm.length > 0 && { community: comm } ),
                              ...( buildings && activeCategory !== 'off-plan' && { building_id: buildings } ),
                              ...( developer && activeCategory === 'off-plan' && developer ),
                              ...( downPayment && activeCategory === 'off-plan' && { downPayment: downPayment } ),
                              ...( paymentPlan && activeCategory === 'off-plan' && { paymentPlan: paymentPlan } ),
                              ...( lType && activeCategory !== 'off-plan' && { listingType: { name: "listingType", value: lType, label: lType, title: 'Purpose' } } ),
                              ...minPrice && { minPrice: { value: minPrice, label: `AED ${ formatValue( minPrice ) }`, title: 'Min Price' } },
                              ...maxPrice && { maxPrice: { value: maxPrice, label: `AED ${ formatValue( maxPrice ) }`, title: 'Max Price' } },
                              ...selectedBeds && { beds: selectedBeds },
                              ...activeCategory && activeCategory !== 'off-plan' && { category: activeCategory },
                              ...selectedConditions.length && selectedConditions.length > 0 && { conditions: selectedConditions },
                            } ) ).toString( 'base64' ),
                          }
                        } }

                      >
                        <a
                          type="button"
                          className="justify-center flex flex-row px-14 py-[0.7rem] rounded-full bg-[#DFC090]
                     dark:bg-night text-navy font-Lexend  font-semibold  button border border-night hover:bg-night hover:border hover:border-[#DFC090] hover:text-[#DFC090]
                       
                       relative overflow-hidden"
                        >
                          Search
                        </a>
                      </Link>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      {/* find your match box end */ }
      <div className="relative md:hidden ">
        <div
          className="absolute 2.5xs:top-[22rem] 2.5xs:left-1/2 
        2.5xs:-translate-x-1/2 2.5xs:-translate-y-1/2 z-40
        4xs:top-[24rem] 4xs:left-1/2 
        4xs:-translate-x-1/2 4xs:-translate-y-[240%]

           3xs:top-[23rem] 3xs:left-1/2 
        3xs:-translate-x-1/2 3xs:-translate-y-[150%]
              2xs:top-[28.5rem] 2xs:left-1/2 
        2xs:-translate-x-1/2 2xs:-translate-y-[150%]
          xs:top-[32.5rem] xs:left-1/2 
        xs:-translate-x-1/2 xs:-translate-y-[150%]
           sm:top-[38rem] sm:left-1/2 
        sm:-translate-x-1/2 sm:-translate-y-[150%]
        "
        >
          <button
            className="bg-[#DFC090] py-2 px-3 2.5xs:px-2 text-night
          rounded-md 4xs:px-1 4xs:text-[0.8rem] 2.5xs:text-[1rem] sm:px-2 
          2.5xs:w-[16rem] 2.5xs:h-[3rem]  
          4xs:w-[12rem] 4xs:h-[3rem]   font-Lexend font-bold
          2xs:w-[20rem] 2xs:h-[3rem]  
          xs:w-[27rem] xs:h-[4rem]   xs:text-[1.3rem]
           sm:w-[31rem] sm:h-[5rem]   sm:text-[1.5rem]
          "
          >
            <Link href={ "/find-the-properties" }>All Ready Properties</Link>
          </button>
        </div>
        <div
          className="absolute 2.5xs:top-[25.5rem]  2.5xs:left-1/2  2.5xs:-translate-x-1/2 
        2.5xs:-translate-y-1/2
         4xs:top-[27.5rem]  4xs:left-1/2  4xs:-translate-x-1/2 4xs:-translate-y-[239%]
         3xs:top-[26.5rem]  3xs:left-1/2  3xs:-translate-x-1/2 3xs:-translate-y-[149%]
          2xs:top-[32rem]  2xs:left-1/2  2xs:-translate-x-1/2 2xs:-translate-y-[149%]
          xs:top-[37.5rem]  xs:left-1/2 xs:-translate-x-1/2 xs:-translate-y-[149%]
           sm:top-[44rem]  sm:left-1/2 sm:-translate-x-1/2 sm:-translate-y-[149%]
          z-40"
        >
          <button
            className="bg-night py-2 px-3  2.5xs:px-2 4xs:px-1 4xs:text-[0.8rem]  2.5xs:text-[1rem]
          2.5xs:w-[16rem] 2.5xs:h-[3rem] 
          4xs:w-[12rem] 4xs:h-[3rem]  
          2xs:w-[20rem] 2xs:h-[3rem]  
                    xs:w-[27rem] xs:h-[4rem]  xs:text-[1.3rem]
                    sm:w-[31rem] sm:h-[5rem]   sm:text-[1.5rem]


          sm:px-2  text-[#FFDEAB] font-bold font-lexend rounded-md"
          >
            <Link href={ "/off-plan-dubai" }>All Off Plan Properties</Link>
          </button>
        </div>
      </div>
      {/* find your match  for mobile start */ }
      <div
        className="lg:hidden block text-sm relative w-[90vw] container mx-auto left-0 right-0 transform sm:translate-y-[45rem] md:translate-y-[28rem] xs:translate-y-[38rem]
       2xs:translate-y-[32rem] 3xs:translate-y-[27rem] 2.5xs:translate-y-[29rem] 4xs:translate-y-[25rem] translate-y-[14rem] bg-night p-2 rounded-[1rem] z-20"
      >
        <div className="grid grid-cols-1">
          <div className="grid-cols-1 pt-2 col-span-4 py-3 pl-[1rem] pr-[1rem] mt-1">
            <div className="grid-rows-4 ">
              <div className="flex justify-center items-center space-x-2 ixs:space-x-3 sm:space-x-3 mb-3">
                { categories?.filter( item => item?.weight || item?.weight !== 0 )?.map( cat => (
                  <button
                    key={ cat?.id }
                    onClick={ () => handleCategoryChange( cat?.id, cat?.category ) }
                    className={ `text-navy font-Lexend font-semibold rounded-full px-3 ixs:px-4 sm:px-5 py-2 cursor-pointer ${ activeCategory?.value === cat?.id ? "bg-[#DFC090]" : "hover:bg-[#ffe8c3] bg-white"
                      }` }
                  >
                    { cat?.category }
                  </button>
                ) )
                }

                <button
                  key={ 'off-plan' }
                  onClick={ () => handleCategoryChange( 'off-plan' ) }
                  className={ ` text-navy font-Lexend font-semibold rounded-full px-3 sm:px-5 ixs:px-4 py-2 whitespace-nowrap cursor-pointer ${ activeCategory === 'off-plan' ? "bg-[#DFC090]" : "hover:bg-[#ffe8c3] bg-white"
                    }` }
                >
                  Off Plan
                </button>

              </div>
              <div className="grid grid-cols-1 gap-3">
                <AutoPopulate setComm={ setComm } setBuilding={ setBuildings } category={ activeCategory } offPlanCommunities={ offPlanCommunities } />

                { activeCategory !== 'off-plan' ? <div className="relative">
                  <button
                    type="button"
                    className="inline-flex justify-between items-center w-full rounded-full border
                     border-night px-4 py-3 bg-white hover:bg-[#FFDEAB] text-base font-semibold font-Lexend text-navy"
                    onClick={ toggleDropdownType }
                  >
                    <span>{ selectedOption }</span>
                    <span>{ isOpenType ? arrowUp : arrowDown }</span>
                  </button>

                  { isOpenType && (
                    <div
                      className="origin-top-left absolute left-0 top-12 w-full mt-2  rounded-md shadow-lg
                   bg-white ring-1 ring-black ring-opacity-5 focus:outline-none p-4"
                      style={ { zIndex: 1000 } }
                    >
                      <ul ref={ listingTypeMobRef }>
                        <li
                          className="mb-4 text-navy font-Lexend text-base font-semibold hover:text-[#99700D] cursor-pointer"
                          onClick={ handleOnClickBuy }
                        >
                          Buy
                        </li>
                        <li
                          className=" text-navy font-Lexend text-base font-semibold hover:text-[#99700D] cursor-pointer"
                          onClick={ handleOnClickRent }
                        >
                          Rent
                        </li>
                      </ul>
                    </div>
                  ) }   </div> :

                  <DropDownOffPlan
                    label="Developer"
                    name='developers'
                    isOpen={ isOpenDeveloper }
                    toggleDropdown={ toggleDropdown }
                    items={ offPlanDevelopers.map( ( e ) =>
                    {

                      return ( { name: 'developers', label: e.name, value: e._id, title: 'Developer' } );
                    } ) }
                    onSelectionChange={ ( item ) =>
                    {
                      return setDeveloper( item );
                    } }
                    selectedItem={ developer ?? {} }
                    containerClassName='w-full text-base font-semibold inline-flex justify-center items-center border border-navy '
                  />
                }
                <div className="relative inline-block text-left z-10">
                  <div>
                    <button
                      type="button"
                      className="inline-flex justify-between items-center w-full rounded-full border border-night px-4 py-3
                       bg-white hover:bg-[#FFDEAB] text-base font-semibold font-Lexend text-navy"
                      onClick={ toggleDropdownFilters }
                    >
                      <span>Filters</span>
                      <span>{ isOpen ? arrowUp : arrowDown }</span>
                    </button>
                  </div>


                </div>
              </div>
              <div className="flex-1  p-1 flex flex-col justify-between  items-center w-full">
                <div className="flex gap-4 flex-row justify-between items-center">
                  <Link
                    passHref
                    href={ {
                      pathname: activeCategory === 'off-plan' ? "/off-plan-dubai" : "/find-the-properties",
                      query: {
                        filter: Buffer.from( JSON.stringify( {
                          // ...( selectedTypes.length && selectedTypes.length > 0 && { propertyType: selectedTypes } ),
                          ...( selectedType && { propertyType: selectedType } ),
                          ...( comm?.length && comm.length > 0 && { community: comm } ),
                          ...( downPayment && activeCategory === 'off-plan' && { downPayment: downPayment } ),
                          ...( paymentPlan && activeCategory === 'off-plan' && { paymentPlan: paymentPlan } ),
                          ...( buildings && activeCategory !== 'off-plan' && { building_id: buildings } ),
                          ...( developer && activeCategory === 'off-plan' && developer ),
                          ...( lType && activeCategory !== 'off-plan' && { listingType: { name: "listingType", value: lType, label: lType, title: 'Purpose' } } ),
                          ...minPrice && { minPrice: { value: minPrice, label: `AED ${ formatValue( minPrice ) }`, title: 'Min Price' } },
                          ...maxPrice && { maxPrice: { value: maxPrice, label: `AED ${ formatValue( maxPrice ) }`, title: 'Max Price' } },
                          ...selectedBeds && { beds: selectedBeds },
                          ...activeCategory && activeCategory !== 'off-plan' && { category: activeCategory },
                          ...selectedConditions.length && selectedConditions.length > 0 && { conditions: selectedConditions },
                        } ) ).toString( 'base64' ),
                      }
                    } }
                  >
                    <a
                      type="button"
                      className="2xl:px-8  justify-content-center flex flex-row mt-[1rem] px-8 py-1 pb-2 rounded-[50px]
                       bg-[#DFC090]  text-navy font-Lexend font-bold lg:text-sm text-sm 
                         border hover:bg-night hover:text-[#DFC090] border-[#DFC090] relative  overflow-hidden"
                    >
                      <span className="pt-[0.2rem]">Search</span>
                    </a>
                  </Link>
                  <div>
                    <button
                      id={ buttonName.getMatched }
                      type="button"
                      className="2xl:px-8  justify-content-center flex flex-row mt-[1rem] px-4 py-2 pb-2 rounded-[50px]
                       bg-night  text-[#FFDEAB] border border-[#FFDEAB] lg:text-sm text-sm font-bold  
                       
                         hover:bg-[#DFC090] hover:text-navy relative  overflow-hidden w-max"
                      onClick={ showGetMatched }
                    >
                      <span className="pt-[0.2rem">Get Matched</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* static clouds on side */ }
        <img
          className="pointer-events-none "
          src="./img/banner/right.png"
          id="myimg"
        />
        <img
          className="pointer-events-none overflow-hidden"
          src="./img/banner/left.png"
          id="myimg2"
        />
      </div>
      {/* find your match  for mobile end */ }
      { isOpen && (
        <div
          className="lg:hidden 4xs:fixed left-0 top-0 w-full h-full  overflow-y-scroll shadow-lg
           bg-white ring-1 ring-black ring-opacity-5 focus:outline-none p-4 !z-50"

          ref={ filterMobRef }
        >
          <div className="flex justify-end items-end">
            <button className="text-navy font-semibold" onClick={ () => setIsOpen( false ) }>Close</button></div>
          <div className="space-y-6 pt-10 ">
            <div className="flex gap-4">
              <div>
                <label className="block text-base font-bold text-gray-700">
                  Min Price
                </label>

                <CurrencyInput
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                  onChange={ handleMinPriceChange }
                  placeholder={ formatValue( 100 ) }
                />
              </div>
              <div>
                <label className="block text-base font-bold text-gray-700">
                  Max Price
                </label>

                <CurrencyInput
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                  onChange={ handleMaxPriceChange }
                  max={ 10000000000 }
                  placeholder={ formatValue( 100000000 ) }
                />
              </div>
            </div>
            { activeCategory.label === 'Commercial' && <fieldset className="grid grid-cols-1 gap-4">
              <legend className="block text-base font-bold font-Lexend text-gray-700">
                Conditions
              </legend>
              { [ "Shell and Core", "Fitted" ].map( ( condition ) => (
                <button
                  key={ condition }
                  className="flex items-center mt-3 rounded-full cursor-pointer"
                >
                  <input
                    id={ condition }
                    name="conditions"
                    type="checkbox"
                    className="absolute opacity-0 w-0 h-0"
                    onChange={ ( e ) => handleConditionCheckboxChange( e, condition, "condition" ) }
                    checked={ selectedConditions.some( e => e.value === condition ) }
                  />
                  <label
                    htmlFor={ condition }
                    className={ `flex items-center w-full h-full rounded-full cursor-pointer hover:bg-[#E3EFFA] hover:text-night
                transition-colors duration-300 ease-in-out shadow-customnavy px-4 py-2
                ${ selectedConditions.some( e => e.value === condition ) ? 'bg-[#E3EFFA] text-night border border-navy shadow-none' : 'bg-white text-gray-700' }` }
                  >
                    <span className="flex items-center justify-center">
                      { selectedConditions.some( e => e.value === condition ) && (
                        <svg className="w-4 h-4 mr-2 text-white" fill="none" stroke="#1e293b" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                        </svg>
                      ) }
                    </span>
                    <span className="block text-sm font-medium">
                      { condition }</span>
                  </label>
                </button>
              ) ) }
            </fieldset> }
            { activeCategory.label !== 'Commercial' &&
              <fieldset className="flex flex-wrap gap-x-3 gap-y-2">
                <legend className="block text-base font-bold font-Lexend text-gray-700">
                  Beds
                </legend>
                { [ 'Studio', '1', '2', '3', '4', '5', '6', '7+' ].map( e => ( { title: 'Beds', name: "beds", label: !isNaN( Number( e.replace( '+', '' ) ) ) ? `${ e } Beds` : e, value: e } ) ).map( ( beds ) => (
                  <button
                    key={ beds.value }
                    className="flex items-center mt-3 rounded-full cursor-pointer">
                    <input
                      id={ beds.value }
                      name="beds"
                      type="checkbox"
                      className="absolute opacity-0 w-0 h-0"
                      onChange={ ( e ) => handleBedsCheckboxChange( e, beds, "beds" ) }
                      checked={ selectedBeds.some( e => e.value === beds.value ) }
                    />
                    <label
                      htmlFor={ beds.value }
                      className={ `flex items-center w-full h-full rounded-full cursor-pointer hover:bg-[#E3EFFA] hover:text-night
                transition-colors duration-300 ease-in-out shadow-customnavy px-4 py-2
                ${ selectedBeds.some( e => e.value === beds.value ) ? 'bg-[#E3EFFA] text-night border border-navy shadow-none' : 'bg-white text-gray-700' }` }
                    >
                      <span className="flex items-center justify-center">
                        { selectedBeds.some( e => e.value === beds.value ) && (
                          <svg className="w-4 h-4 mr-2 text-white" fill="none" stroke="#1e293b" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                          </svg>
                        ) }
                      </span>
                      <span className="block text-sm font-medium">
                        { beds.label }
                      </span>
                    </label>
                  </button>
                ) ) }
              </fieldset> }
            { activeCategory === 'off-plan' && <fieldset className="flex flex-wrap gap-1">
              <legend className="block text-base font-bold font-Lexend text-gray-700">
                Payment Plan
              </legend>
              { [ 'full payment on delivery', 'post-handover payment plan' ].map( e => ( { name: 'paymentPlan', label: toTitleCase( e ), value: e } ) ).map( ( val ) => (
                <div
                  key={ val.value }
                  className="relative flex items-center mt-3 cursor-pointer"

                >
                  <input
                    id={ val.value }
                    name="paymentPlan"
                    type="checkbox"
                    className="absolute opacity-0 w-0 h-0"
                    onChange={ ( e ) => paymentPlan?.value !== val.value ? setPaymentPlan( val ) : setPaymentPlan() }
                    checked={ paymentPlan?.value === val.value }
                  />
                  <label
                    htmlFor={ val.value }
                    className={ `flex items-center w-fit h-full  rounded-full mr-4 transition-colors
                                       cursor-pointer shadow-customnavy px-4 py-2 hover:bg-[#E3EFFA] hover:text-navy
                                       duration-300 ease-in-out ${ paymentPlan?.value === val.value ?
                        'bg-[#E3EFFA] text-navy border border-night shadow-none' : 'bg-white text-gray-700'
                      }` }
                  >
                    <span className="flex items-center justify-center">
                      { paymentPlan?.value === val.value && (
                        <svg className="w-4 h-4 mr-2 text-white" fill="none" stroke="#1a212c" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                        </svg>
                      ) }
                    </span>
                    <span className="block text-sm font-medium">
                      { val.label }
                    </span>
                  </label>
                </div>
              ) ) }
            </fieldset> }


            { activeCategory === 'off-plan' && <fieldset className="flex flex-wrap gap-1">
              <legend className="block text-base font-bold font-Lexend text-gray-700">
                Down Payment
              </legend>
              { [ 'Less than 20%', '20-30%', 'More than 30%' ].map( e => ( { name: 'downPayment', label: e, value: e === 'Less than 20%' ? JSON.stringify( { max: 20 } ) : e === '20-30%' ? JSON.stringify( { min: 20, max: 30 } ) : JSON.stringify( { min: 30 } ) } ) ).map( ( val ) => (
                <div
                  key={ val.value }
                  className="relative flex items-center mt-3 cursor-pointer"

                >
                  <input
                    id={ val.value }
                    name="downPayment"
                    type="checkbox"
                    className="absolute opacity-0 w-0 h-0"
                    onChange={ ( e ) => downPayment?.value !== val.value ? setDownPayment( val ) : setDownPayment() }
                    checked={ downPayment?.value === val.value }
                  />
                  <label
                    htmlFor={ val.value }
                    className={ `flex items-center w-fit h-full  rounded-full mr-4 transition-colors
                                       cursor-pointer shadow-customnavy px-4 py-2 hover:bg-[#E3EFFA] hover:text-navy
                                       duration-300 ease-in-out ${ downPayment?.value === val.value ?
                        'bg-[#E3EFFA] text-navy border border-night shadow-none' : 'bg-white text-gray-700'
                      }` }
                  >
                    <span className="flex items-center justify-center">
                      { downPayment?.value === val.value && (
                        <svg className="w-4 h-4 mr-2 text-white" fill="none" stroke="#1a212c" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                        </svg>
                      ) }
                    </span>
                    <span className="block text-sm font-medium">
                      { val?.label }
                    </span>
                  </label>
                </div>
              ) ) }
            </fieldset> }
            <fieldset className="flex flex-wrap gap-3">
              <legend className="block text-base font-bold font-Lexend text-gray-700">
                Type
              </legend>
              { typeToIdMap?.map( ( type ) => (
                <button key={ type } className="flex items-center mt-3 rounded-full cursor-pointer">
                  <input
                    id={ type?.id }
                    name={ type?.propertyType }
                    type="checkbox"
                    className="absolute opacity-0 w-0 h-0"
                    onChange={ ( e ) =>
                      handleCheckboxChange( e, type?.id, type?.propertyType )
                    }
                    // checked={ selectedTypes.some( ( e ) => e.value === type?.id ) }
                    checked={ selectedType?.value === type?.id }
                  />
                  {/* ${ selectedTypes.some( e => e.value === type?.id ) ? 'bg-[#E3EFFA] text-night border border-navy shadow-none' : 'bg-white text-gray-700' } */ }
                  <label
                    htmlFor={ type?.id }
                    className={ `flex items-center w-full h-full rounded-full cursor-pointer hover:bg-[#E3EFFA] hover:text-night
                                transition-colors duration-300 ease-in-out shadow-customnavy px-4 py-2
                                ${ selectedType?.value === type.id ? 'bg-[#E3EFFA] text-navy border border-night shadow-none' : 'bg-white text-gray-700' }` }

                  >
                    <span className="flex items-center justify-center">
                      {/* { selectedTypes.some( e => e.value === type?.id ) && ( */ }
                      { selectedType?.value === type.id && (
                        <svg className="w-4 h-4 mr-2 text-white" fill="none" stroke="#1e293b" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                        </svg>
                      ) }</span>
                    <span className="block text-sm font-medium">
                      { type?.propertyType }
                    </span>
                  </label>
                </button>
              ) ) }
            </fieldset>

            <Link
              passHref
              href={ {
                pathname: activeCategory === 'off-plan' ? "/off-plan-dubai" : "/find-the-properties",
                query: {
                  filter: Buffer.from( JSON.stringify( {
                    // ...( selectedTypes.length && selectedTypes.length > 0 && { propertyType: selectedTypes } ),
                    ...( selectedType && { propertyType: selectedType } ),
                    ...( comm?.length && comm.length > 0 && { community: comm } ),
                    ...( downPayment && activeCategory === 'off-plan' && { downPayment: downPayment } ),
                    ...( paymentPlan && activeCategory === 'off-plan' && { paymentPlan: paymentPlan } ),
                    ...( buildings && activeCategory !== 'off-plan' && { building_id: buildings } ),
                    ...( developer && activeCategory === 'off-plan' && developer ),
                    ...( lType && activeCategory !== 'off-plan' && { listingType: { name: "listingType", value: lType, label: lType, title: 'Purpose' } } ),
                    ...minPrice && { minPrice: { value: minPrice, label: `AED ${ formatValue( minPrice ) }`, title: 'Min Price' } },
                    ...maxPrice && { maxPrice: { value: maxPrice, label: `AED ${ formatValue( maxPrice ) }`, title: 'Max Price' } },
                    ...selectedBeds && { beds: selectedBeds },
                    ...activeCategory && activeCategory !== 'off-plan' && { category: activeCategory },
                    ...selectedConditions.length && selectedConditions.length > 0 && { conditions: selectedConditions },
                  } ) ).toString( 'base64' ),
                }
              } }

            >
              <button className="flex justify-center py-2 px-4 border border-transparent
                           rounded-md shadow-sm text-base font-bold text-navy bg-[#DFC090] hover:bg-night hover:text-[#FFDEAB] ">
                Apply Filters
              </button>

            </Link>
          </div>
        </div>
      ) }
    </div>
  );
};

export default HomeBanner;
