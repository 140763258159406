import * as types from "../types";
import axios from "axios";
import { serverAPI } from "../../utils/envConfig";

export const fetchProperties = () => async (dispatch) => {
  const headers = {
    "Content-Type": "application/json",
  };
  try {
    const res = await axios.get(`${serverAPI}/search/query/property?`);
    dispatch({
      type: types.GET_PROPERTIES,
      payload: res.data.findPropListsOutput,
    });
  } catch (error) {
    console.log(error);
  }
};
export const fetchFeatured = () => async (dispatch) => {
  const headers = {
    "Content-Type": "application/json",
  };
  try {
    const res = await axios.get(
      `${serverAPI}/search/query/property?featured=true`
    );
    dispatch({
      type: types.GET_FEATURED_PROPERTIES,
      payload: res.data.findPropListsOutput,
    });
  } catch (error) {
    console.log(error);
  }
};
export const fetchDistressedAndPublishedProperties = () => async (dispatch) => {
  const headers = {
    "Content-Type": "application/json",
  };
  try {
    const res = await axios.get(`${serverAPI}/filter/propList?distress=true&status=Active`);
    dispatch({
      type: types.GET_DISTRESSED_PUBLISHED_PROPS,
      payload: res.data.findPropListsOutput,
    });
  } catch (error) {
    console.log(error);
  }
};
export const fetchLastUpdatedProperties = () => async (dispatch) => {
  const headers = {
    "Content-Type": "application/json",
  };
  try {
    const res = await axios.get(
      `${serverAPI}/search/query/property?statusArray[]=Published&statusArray[]=Private`
    );
    dispatch({
      type: types.GET_LASTUPDATED_PROPERTIES,
      payload: res.data.findPropListsOutput,
    });
  } catch (error) {
    console.log(error);
  }
};
export const fetchDeal = () => async (dispatch) => {
  try {
    const res = await axios.get(
      `${serverAPI}/search/query/property?dealOfWeek=true`
    );
    dispatch({
      type: types.GET_DEAL_OF_THE_WEEK,
      payload: res.data.findPropListsOutput,
    });
  } catch (error) {
    console.log(error);
  }
};
export const fetchAmenities = () => async (dispatch) => {
  const headers = {
    "Content-Type": "application/json",
  };
  try {
    const res = await axios.get(`${serverAPI}/amenity`);
    dispatch({
      type: types.GET_AMENITIES,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};
export const fetchCommunities = () => async (dispatch) => {
  const headers = {
    "Content-Type": "application/json",
  };
  try {
    const res = await axios.get(`${serverAPI}/community/withProperties`);
    dispatch({
      type: types.GET_COMMUNITIES,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};
export const fetchPropTypes = () => async (dispatch) => {
  const headers = {
    "Content-Type": "application/json",
  };
  try {
    const res = await axios.get(`${serverAPI}/propType`);
    dispatch({
      type: types.GET_PROP_TYPES,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};
export const fetchCategory = () => async (dispatch) => {
  const headers = {
    "Content-Type": "application/json",
  };
  try {
    const res = await axios.get(`${serverAPI}/category`);
    dispatch({
      type: types.GET_CATEGORY,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};
export const handleBuyOrRent = (data) => async (dispatch) => {
  dispatch({
    type: types.BUY_OR_RENT,
    payload: data,
  });
};
