import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from 'axios';
import { serverAPI } from '../utils/envConfig';
import { LOOP } from '@splidejs/splide';

export default function AutoPopulate ( { setComm, setBuilding, category, offPlanCommunities } )
{
  const community = useSelector( ( state ) => state.property.communities );

  const [ customOptions, setCustomOptions ] = useState( [] );
  const [ suggestions, setSuggestions ] = useState( [] );
  const [ inputSearch, setInputSearch ] = useState( "" );

  const [ result, setResult ] = useState( [] );
  const [ lastCategory, setLastCategory ] = useState();
  const [ isModalOpen, setIsModalOpen ] = useState( false );

  const theme = useTheme();
  const isSmallScreen = useMediaQuery( theme.breakpoints.down( 'xl' ) );

  useEffect( () =>
  {
    if ( ( lastCategory !== 'off-plan' && category === 'off-plan' ) || ( lastCategory === 'off-plan' && category !== 'off-plan' ) )
    {
      setResult( [] );
      setComm();
    }
    let options;
    if ( category === 'off-plan' )
    {
      options = offPlanCommunities.map( ( ele ) => ( {
        label: ele.name,
        value: ele._id,
        name: "community"
      } ) );
    } else
    {
      options = suggestions.map( ( ele ) => ( {
        label: ele?.type === 'BUILDING' ? ele.name : ele?.community,
        value: ele.id,
        name: ele.type == "COMMUNITY" ? "community" : "building_id",
        title: ele.type == "COMMUNITY" ? "Community" : "Building",
      } ) );
    }
    setCustomOptions( options );
    setLastCategory( category );
  }, [ suggestions, category, offPlanCommunities ] );

  const handleModalOpen = () =>
  {
    setIsModalOpen( true );
  };

  const handleModalClose = () =>
  {
    setIsModalOpen( false );
  };

  const handleRemoveItem = ( valueToRemove ) =>
  {
    const updatedResult = result.filter( ( item ) => item.value !== valueToRemove );
    setResult( updatedResult );

    if ( updatedResult.length === 0 )
    {
      setIsModalOpen( false );
    }
  };

  const fetchSuggestions = async () =>
  {
    try
    {
      const result = await axios.post( `${ serverAPI }/globalSearch`, { keyword: inputSearch, } );
      setSuggestions( result.data );
    } catch ( error )
    {
      console.log( error );
    }
  };

  const [ open, setIsOpen ] = useState( false );

  useEffect( () =>
  {
    if ( inputSearch )
    {

      fetchSuggestions();
      setIsOpen( true );
    } else
    {
      setSuggestions( [] );
      setIsOpen( false );
    }
  }, [ inputSearch ] );

  const handleSearchText = ( event ) =>
  {
    setInputSearch( event?.target?.value );
  };

  return (
    <div style={ { backgroundColor: 'white', borderRadius: '9999px', maxHeight: "22rem !important" } }>
      <Autocomplete
        multiple
        id="customized-autocomplete"
        options={ customOptions?.filter( ( item => !result?.find( elem => elem?.value === item?.value ) ) ) }
        getOptionLabel={ ( option ) => option.label }
        value={ result }
        open={ open }
        onClose={ () => setIsOpen( false ) }
        onChange={ ( e, v ) =>
        {
          setResult( v );
          let comms = v?.filter( ( e ) => e.name === 'community' );
          setComm( comms );
          let buildings = v?.filter( ( e ) => e.name === 'building_id' );
          setBuilding( buildings );
        } }
        onInputChange={ handleSearchText }
        renderTags={ ( value, getTagProps ) =>
          value.map( ( option, index ) =>
            ( isSmallScreen ? index < 1 : index < 1 ) ? (
              <Chip
                key={ option.value }
                label={ option.label }
                { ...getTagProps( { index } ) }
                sx={ {
                  backgroundColor: '#DFC090',
                  maxWidth: "8rem !important",
                  color: 'black',
                  '& .MuiChip-deleteIcon': {
                    color: '#2A384A',
                  },
                  '&:hover': {
                    backgroundColor: '#FFDEAB',
                  },
                } }
              />
            ) : null
          ).concat(
            value.length > ( isSmallScreen ? 1 : 1 ) ? [
              <Chip
                key="more"
                label={ `${ value.length - ( isSmallScreen ? 1 : 1 ) } more` }
                onClick={ handleModalOpen }
                sx={ {
                  cursor: 'pointer',
                  borderRadius: '9999px',
                  backgroundColor: '#DFC090',
                  '&:hover': {
                    backgroundColor: '#FFDEAB',
                  },
                } }
              />,
            ] : []
          )
        }
        renderInput={ ( params ) => (
          <TextField
            { ...params }
            label=""

            placeholder="Community or Building"
            sx={ {

              '& .MuiOutlinedInput-root': {
                borderRadius: '9999px',
                paddingLeft: '0.8rem',
                paddingTop: '0.4rem',
                paddingBottom: '0.4rem',

                '& fieldset': {
                  borderColor: '#fff',

                },
                '&:hover fieldset': {
                  borderColor: '#6b7280',


                },
                '&.Mui-focused fieldset': {
                  border: 'none',
                  maxWidth: "12rem !important",


                },
              },
              '& .MuiInputBase-input': {
                borderRadius: '9999px',
                maxWidth: "12rem !important",


              },
            } }
          />
        ) }
        sx={ {
          width: '100%',
          minWidth: '35rem',
          '.MuiAutocomplete-listbox': {
            borderRadius: '9999px',

          },
          '@media (max-width: 900px)': {
            minWidth: '22rem',
            width: '100%',
          },
          '@media (min-width: 768px)': {
            minWidth: '4rem',
            width: '100%',
          }, '@media (min-width: 1024px)': {
            minWidth: '22rem',
            width: '100%',
          },
          '@media (max-width: 450px)': {
            minWidth: '12rem',
            width: '100%',
          },
          '@media (min-width: 1536px)': {
            minWidth: '33rem',
            width: '100%',

          }
        } }
      />
      <Dialog onClose={ handleModalClose } open={ isModalOpen } fullWidth>
        <DialogTitle className='text-navy font-lexend font-bold'>
          Selected Communities
          <IconButton
            aria-label="close"
            onClick={ handleModalClose }
            sx={ {
              position: 'absolute',
              right: 8,
              top: 8,
              color: ( theme ) => theme.palette.grey[ 500 ],
            } }
          >
            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.4158 8.00409L15.7158 1.71409C15.9041 1.52579 16.0099 1.27039 16.0099 1.00409C16.0099 0.73779 15.9041 0.482395 15.7158 0.294092C15.5275 0.105788 15.2721 0 15.0058 0C14.7395 0 14.4841 0.105788 14.2958 0.294092L8.0058 6.59409L1.7158 0.294092C1.52749 0.105788 1.2721 2.36434e-07 1.0058 2.38419e-07C0.739497 2.40403e-07 0.484102 0.105788 0.295798 0.294092C0.107495 0.482395 0.00170684 0.73779 0.00170684 1.00409C0.00170684 1.27039 0.107495 1.52579 0.295798 1.71409L6.5958 8.00409L0.295798 14.2941C0.20207 14.3871 0.127676 14.4977 0.0769072 14.6195C0.0261385 14.7414 0 14.8721 0 15.0041C0 15.1361 0.0261385 15.2668 0.0769072 15.3887C0.127676 15.5105 0.20207 15.6211 0.295798 15.7141C0.388761 15.8078 0.499362 15.8822 0.621222 15.933C0.743081 15.9838 0.873786 16.0099 1.0058 16.0099C1.13781 16.0099 1.26852 15.9838 1.39038 15.933C1.51223 15.8822 1.62284 15.8078 1.7158 15.7141L8.0058 9.41409L14.2958 15.7141C14.3888 15.8078 14.4994 15.8822 14.6212 15.933C14.7431 15.9838 14.8738 16.0099 15.0058 16.0099C15.1378 16.0099 15.2685 15.9838 15.3904 15.933C15.5122 15.8822 15.6228 15.8078 15.7158 15.7141C15.8095 15.6211 15.8839 15.5105 15.9347 15.3887C15.9855 15.2668 16.0116 15.1361 16.0116 15.0041C16.0116 14.8721 15.9855 14.7414 15.9347 14.6195C15.8839 14.4977 15.8095 14.3871 15.7158 14.2941L9.4158 8.00409Z" fill="#263250" />
            </svg>
          </IconButton>
        </DialogTitle>
        <DialogContent dividers sx={ { padding: '20px' } }>
          <List>
            { result.map( ( item ) => (
              <ListItem
                key={ item.value }
                className='text-navy font-lexend font-bold '
                secondaryAction={
                  <IconButton edge="end" aria-label="delete" onClick={ () => handleRemoveItem( item.value ) }>
                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.4158 8.00409L15.7158 1.71409C15.9041 1.52579 16.0099 1.27039 16.0099 1.00409C16.0099 0.73779 15.9041 0.482395 15.7158 0.294092C15.5275 0.105788 15.2721 0 15.0058 0C14.7395 0 14.4841 0.105788 14.2958 0.294092L8.0058 6.59409L1.7158 0.294092C1.52749 0.105788 1.2721 2.36434e-07 1.0058 2.38419e-07C0.739497 2.40403e-07 0.484102 0.105788 0.295798 0.294092C0.107495 0.482395 0.00170684 0.73779 0.00170684 1.00409C0.00170684 1.27039 0.107495 1.52579 0.295798 1.71409L6.5958 8.00409L0.295798 14.2941C0.20207 14.3871 0.127676 14.4977 0.0769072 14.6195C0.0261385 14.7414 0 14.8721 0 15.0041C0 15.1361 0.0261385 15.2668 0.0769072 15.3887C0.127676 15.5105 0.20207 15.6211 0.295798 15.7141C0.388761 15.8078 0.499362 15.8822 0.621222 15.933C0.743081 15.9838 0.873786 16.0099 1.0058 16.0099C1.13781 16.0099 1.26852 15.9838 1.39038 15.933C1.51223 15.8822 1.62284 15.8078 1.7158 15.7141L8.0058 9.41409L14.2958 15.7141C14.3888 15.8078 14.4994 15.8822 14.6212 15.933C14.7431 15.9838 14.8738 16.0099 15.0058 16.0099C15.1378 16.0099 15.2685 15.9838 15.3904 15.933C15.5122 15.8822 15.6228 15.8078 15.7158 15.7141C15.8095 15.6211 15.8839 15.5105 15.9347 15.3887C15.9855 15.2668 16.0116 15.1361 16.0116 15.0041C16.0116 14.8721 15.9855 14.7414 15.9347 14.6195C15.8839 14.4977 15.8095 14.3871 15.7158 14.2941L9.4158 8.00409Z" fill="#263250" />
                    </svg>
                  </IconButton>
                }
              >
                <ListItemText primary={ item.label } />
              </ListItem>
            ) ) }
          </List>
        </DialogContent>
      </Dialog>
    </div>
  );
}
